<template>
    <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <!-- {{ transactionInfo }} -->

        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="Amount" prop="amount">
                <!-- <el-input type="number" v-model="ruleForm.amount" autocomplete="off"></el-input> -->
                <el-input-number v-model="ruleForm.amount" :precision="2" :step="0.1" :min="0.01" :max="transactionInfo.paidAmount"></el-input-number>
            </el-form-item>
            <el-form-item label="Reason" prop="refundReason">
                <el-select v-model="ruleForm.refundReason" placeholder="Reason" autocomplete="off">
                    <el-option label="Duplicate" value="DUPLICATE"></el-option>
                    <el-option label="Fraudulent" value="FRAUDULENT"></el-option>
                    <el-option label="Requested by customer" value="REQUESTED_BY_CUSTOMER"></el-option>
                    <el-option label="Other" value="OTHER"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Notes" prop="description">
                <el-input type="textarea" v-model="ruleForm.description"></el-input>
            </el-form-item>
            <!-- <el-form-item label="Age" prop="age">
              <el-input v-model.number="ruleForm.age"></el-input>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                <el-button @click="resetForm('ruleForm')">Reset</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import PaymentMethods from "@/mixins/payments";
import axios from 'axios';
import config from "@/config/app";
import SysAxios from "@/config/axios";
export default {
    name: "paymentRefund",
    props: {
        transactionInfo: {
            type: Object,
        },
        data: {
            type: Object,
        },
        entityDataId:{
            type:String,
        },
        templateId:{
            type:String
        }
    },
    mixins: [PaymentMethods],

    computed: {
    },
    async mounted() {
        this.ruleForm.amount= this.transactionInfo.paidAmount
    },

    data() {
        var checkAmount = (rule, value, callback) => {
            let amount = Number(parseFloat(value).toFixed(2));
            if (!amount) {
                return callback(new Error('Please enter the amount'));
            }
            setTimeout(() => {
                if ( (typeof amount) !=='number') {
                    callback(new Error('Please input numbers only'));
                } else {
                    if (amount < 0) {
                        // console.log(typeof amount)
                        callback(new Error('Amount must be greater than 0'));
                    }
                    else if(amount> this.transactionInfo.paidAmount)
                    {
                        callback(new Error(`Amount must be Less than ${this.transactionInfo.paidAmount }`));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };
        var reasonValidate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please select the reason'));
            }
            else {
                callback();
            }
            // else {
            //   if (this.ruleForm.checkPass !== '') {
            //     this.$refs.ruleForm.validateField('checkPass');
            //   }
            //   callback();
            // }
        };
        //   var validatePass2 = (rule, value, callback) => {
        //     if (value === '') {
        //       callback(new Error('Please input the password again'));
        //     } else if (value !== this.ruleForm.pass) {
        //       callback(new Error('Two inputs don\'t match!'));
        //     } else {
        //       callback();
        //     }
        //   };
        return {
            loading: false,
            loadingText: 'Loading',
            ruleForm: {
                amount: '',
                refundReason: '',
                description: ''
            },
            rules: {
                amount: [
                    { validator: checkAmount, trigger: 'blur' }
                ],
                refundReason: [
                    { validator: reasonValidate, trigger: 'change' }
                ],
                description: [
                    { validator: reasonValidate, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        async submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    this.loading = true;
                    this.loadingText = "Please valid we are preparing the information to refund the amount";

                    let payload = {
                        action: 'reverse',
                        partnerAccountId: null,
                        trnId: this.transactionInfo.paymentId,
                        description: this.ruleForm.description,
                        refundReason: this.ruleForm.refundReason,
                        authorization_amount: this.ruleForm.amount,
                        original_transaction_id: this.transactionInfo.paymentId,
                        authorization_code: this.transactionInfo.authCode,
                    }
                    try{
                    const refundPayment = await axios.post(`${config.PAYMENT_GATEWAY_URL}/refund?pgAccountId=${this.transactionInfo.pgAccId}&txnAcId=${this.transactionInfo.txnAcId}`, payload);

                    console.log(refundPayment.data.data)

                    let updateStatus= {
                        entityDataId:this.entityDataId,
                        templateId:this.templateId,
                        btnReference:this.data.key,
                        transaction: {...refundPayment.data.result,description: this.ruleForm.description,
                        refundReason: this.ruleForm.refundReason,}
                    }
                    this.loading=true;
                    this.loadingText="Please wait updating the status."
                    try{
                    await SysAxios.post(`/updateDummyTransaction`,updateStatus);
                    }
                    catch(error)
                    {
                        this.$message("error",error.message)
                    }


                    this.$alert(`<p>Refund initiated successfully.</p>
                    `, 'Refund Info', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: 'Ok',
                        showClose:false,
                        closeOnClickModal:false,
                        closeOnPressEscape:false,
                        closeOnHashChange:false,
                    }).then(action => {
                        console.log("Confirm ",action)
                        this.$router.go();

                        this.loading=false;
                        this.loadingText="Loading";
                        });
                    }
                    catch(error)
                    {
                        console.log(error)
                        this.$notify({
                            title: "Error",
                            message: error.response.data.data.error.response.response_desc,
                            type: "error",
                        });
                        this.loading=false;
                        this.loadingText="Loading";
                    }


                } else {
                    console.log('error submit!!');
                    return;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }

};
</script>

<style lang="scss" scoped></style>
